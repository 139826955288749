/*������������� ��� � 4.0*/

#theme-container{
	position:absolute;
	left:0px;
	top:0px;
    min-width: 940px;
    width: 100%;
	z-index:0;
}
.theme-left, .theme-right{
	position:absolute;
    left: 50%;
}

.flex-grow-1 {
	flex-grow: 1;
}

.flex-grow-0 {
	flex-grow: 0;
}

.flex-shrink-1 {
	flex-shrink: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}