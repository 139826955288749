@import "./_settings.scss";

html * {
    max-height: 999999px;
}

html {
    font-size: 16px;
    overflow-y: scroll; // task 32002
}

body {
    font-size: 13px;
    font-family: Tahoma, "Helvetica Neue", Helvetica, Geneva, Arial, sans-serif;
    line-height: 1.5;
    position: relative;
}

label {
    position: relative;
    display: inline-block;
}

figure {
    margin: 0;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
}

img{
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}
.img-not-resize {
    max-height: none;
    max-width: none;
}
p {
    padding: 0;
    margin: 0 0 1.0625rem;
}

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner {
    border: none;
    padding: 0;
}

figure {
    margin: 0;
}

.container {
    max-width: 73.75rem;
    min-width: 60.625rem;
    position: relative;
    margin: 0 auto;
    z-index: 30;
}

.site-menu-row {
    border-radius: $border-radius-base;
}

.site-body-menu {
    padding: 0.625rem 0.625rem 0 0.625rem;
    z-index: 20;
}

.site-head, .site-body-aside, .site-body, .site-body-menu, .site-body-main, .site-footer {
    position: relative;
}

.site-head {
    z-index: 31;
}

.site-body, .site-body-main {
    z-index: 15;
}

.site-body-main:hover {
    z-index: 30;
}

.site-body, .site-body > .container, .site-body-inner {
    /*height: 100%;*/
}

.products-specials-container {
    z-index: 20;
}

.site-body-inner {
    border-radius: 0.3125rem;
}

.site-footer {
    z-index: 10;
}

.disable-hover, .disable-hover * {
    pointer-events: none;
}

.stretch-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    //меню горизонтальное, если большое сайт прыгает на touch устройствах
    overflow: hidden;
}


@media (max-width: $max-width) {
    .stretch-container {
        max-width: $max-width;
        min-width: $min-width;
    }
}

.stretch-middle {
    flex: 1 0 auto;
}

.footer-container {
    max-width: $max-width;
    min-width: $min-width;
}

@media (max-width: 980px) {
    .footer-container {
        max-width: none;
        min-width: 0;
    }
}

.storeclosed-top {
    padding: 15px 0;
    /*background: #ff9bb9;
    color: white;*/
    font-size: 16px;
}

.storeclosed-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.storeclosed-col {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}

.storeclosed-text-align {
    text-align: center;
}

.storeclosed-link {
    text-decoration: solid;
    text-decoration-line: underline;

    &:hover {
        color:inherit;
        opacity:0.7;
        text-decoration: solid;
        text-decoration-line: underline;
    }
}

.glimpse-nowrap {
    z-index: 200;
}

.discount-by-time {
    min-height: 100px;
    min-width: 200px;
}

.buy-one-ckick-very-top {
    z-index: 1050;
}

.gallery-logo-slider {
    padding: 0 30px;
}

form.ng-invalid.ng-submitted .shipping-empty {
    color: red;
}

form.ng-invalid.ng-submitted .payment-empty {
    color: red;
}

.adv-modal .modal-header.modal-product-rotate-header-wrap {
    border: 0;
}

.site-layout-empty-container {
    max-width: 1200px;
    margin: 0 auto;
}

blockquote {
    margin: 10px 0 10px 20px;
    padding: 2px 0 2px 10px;
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 0 5px;
}
 /*Templates ckeditor*/
.block-img-left .wrapper-img:after {
    content: '';
    display: table;
    width: 10px;
    height: 100%;
}

.block-img-left ol,
.block-img-left ul,
.block-img-left dl {
    list-style-position: inside;
}

@media( max-width:768px) {

    .mobile-p-r-none {
        padding-right: 0;
    }

    .mobile-p-l-none {
        padding-left: 0;
    }
}

@media( min-width:768px) {


    .desktop-col-p-v {
        padding-top: .6rem;
        padding-bottom: .6rem;
    }

}

.auth-social {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}
.oc-lazy-load-cloak {
    display: none;
}

.col-p-v-desktop {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}


.tech-domain-info-close {
    position: absolute;
    top: 20%;
    right: 20%;
    cursor:pointer;
}

.footer-payment {
    &__list {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__item {
        margin-right:20px;
    }
}