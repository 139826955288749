@import "../functions/_util.scss", "../functions/_list.scss", "../functions/_prefixer.scss", "../_settings.scss";

$btn-border-radius: rem-calc(5px) !default;
$btn-big: (padding (rem-calc(13px) rem-calc(13px) rem-calc(13px)), font-size rem-calc(16px), is-bold true) !default;
$btn-large: (padding (rem-calc(12px) rem-calc(24px)), font-size $font-size-medium, is-bold false) !default;
$btn-middle: (padding rem-calc(12px), font-size $font-size-normal, is-bold true) !default;
$btn-small: (padding (rem-calc(9.5px) rem-calc(10px) rem-calc(9.5px)), font-size $font-size-normal, is-bold true) !default;
$btn-xsmall: (padding (rem-calc(5px) rem-calc(8px) rem-calc(5px)), font-size $font-size-normal, is-bold true) !default;
$btn-small-padding-x: rem-calc(10px);
$btn-middle-padding-x: rem-calc(12px);
$btn-border-width: 0px;

@mixin btn-size($btn-size-param) {
  @if (list-find-value($btn-size-param, is-bold)) {
    font-weight: bold;
  } @else {
    font-weight: normal;
  }

  font-size: list-find-value($btn-size-param, font-size);
  line-height: list-find-value($btn-size-param, line-height);
  padding: list-find-value($btn-size-param, padding);
}

.btn {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  border-width: 0;
  text-align: center;
  border-radius: $btn-border-radius;
  line-height: 1.3; //обязательно указывать это свойство, иначе высота кнопок input и "a" разная
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid black;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-expander {
  width: 100%;
}

.btn--upper {
  text-transform: uppercase;
}

.btn[class^="icon-"]:before,
.btn[class^="icon-"]:after,
.btn[class*=" icon-"]:before,
.btn[class*=" icon-"]:after {
  font-size: $font-size-medium;
}

.btn-link {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn-big {
  @include btn-size($btn-big)
}

.btn-middle {
  @include btn-size($btn-middle)
}

.btn-small {
  @include btn-size($btn-small)
}

.btn-xsmall {
  @include btn-size($btn-xsmall)
}

.btn--inactive {
  background-color: #f3f3f3;
  color: #000;
}