@import "../../../../styles/_settings.scss";
@import "../../../../styles/functions/_util.scss";

.reviews-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .reviews-list {
        padding: rem-calc(10px) 0 $margin-value-base rem-calc(20px);
    }
}

.review-item-name,
.review-item-date {
    padding-right: rem-calc(15px);
    line-height: 1;
    vertical-align: middle;
}

.review-item-image {
    padding: 0 10px 10px 0;
}

.review-item-name {
    font-size: rem-calc(13px);
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    padding-right: 0.9375rem;
    gap: 5px;
}

.review-item-date {
    font-size: $font-size-small;
    padding-left: rem-calc(15px);
    border-left: rem-calc(1px) #e5e5e5 solid;
}

.is-mobile .review-item-date{
    flex-grow: 1;    
}

.review-item {
    /*margin-bottom: rem-calc(40px);*/
    margin-bottom: 20px;

    .reviews-list {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .review-item {

        &:last-child {
            margin-bottom: 0;
        }
    }

    .review-form-block {
        margin-top: $margin-value-base;
    }
}

.review-form-header {
    font-size: $font-size-xmedium;
    margin-bottom: rem-calc(25px);
    line-height: 1;
}

.review-shift-right {
    padding-right: rem-calc(3px);
}

.review-shift-left {
    padding-left: rem-calc(3px);
}

.review-item-button {
    margin-right: $margin-value-base;
}

.review-item-text {
    padding: rem-calc(20px) 0 0;
    display: block;
}

.review-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -6px;
    margin-right: -6px;
}

.review-photos__item {
    flex-basis: 80px;
    max-width: 80px;
    height: 80px;
    padding: 6px;
    position: relative;
}

.review-photos__delete {
    border-radius: 50px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #fff;
    border: 1px solid currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.review-photos__wrap-thumbs {
    display: inline-flex;
    border: 1px solid;
    font-size: 0;
    border-radius: $border-radius-base;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 6px;
    box-sizing: border-box;
}

.review-photos__thumbs {
}

.reviews-captcha {
    margin: 0;
}

.review-item-buttons {
    margin-top: 20px;
}

.review-item-rating {
    display: inline-block;
    vertical-align: middle;
    margin-left: 40px;
}

.review-item-rating__item {
    display: inline-block;
    vertical-align: middle;
}

.review-item-rating__count--likes {
    color: #298800;
}

.review-item-rating__count--dislikes {
    color: #f81f1f;
}

.review-item__photo-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 20px -6px 0 -6px;
    padding: 0;
}

.review-item__photo-item {
    padding: 6px;
    margin: 0;
}

.review-item__head {
    display: flex;
    align-items: center;
}
.review-item__product-rating{
    white-space: nowrap;
    margin-top: 10px;
}